

import kirbyimage from './kirbyimage.vue' 
import KirbyLink from './kirbylink.vue'
export default {
    name: "tornimagelefttextright",
    props:{
        content: Object
    },
    components: {
        kirbyimage, KirbyLink
    }
}

